import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Design from "./Design";

const resources = {
  tr: {
    translation: {
      home: "Anasayfa",
      works: "Çalışmalarım",
      skills: "Becerilerim",
      poems: "Şiirlerim",
      contact: "İletişim",
     blog:"Blog",
      // Home Section
      hometitle: "Bursa'da Yaşayan Tutkulu Bir Web Geliştiricisi.",
      homecontent:
        "Merhaba! Benim adım Ömer Kaya Ekici. Front-end geliştiricisiyim ve  işime büyük bir tutku ve bağlılıkla yaklaşıyorum. Profesyonel bir  geliştirici olarak, 1 yıl serbest çalışma ve 1 yıl kurumsal  deneyimimle toplamda 2 yıl tecrübeye sahibim. Bu süre zarfında,  projelerinizi başarılı bir şekilde tamamlamak için gerekli bilgi ve  becerileri edindim. ",
      homebuttonworks: "Çalışmalarıma Göz At",
      homebuttoncv: "CV Görüntüle",
      //Works
      //WhereIWorks
      whereıworkstitle: "Daha Önce Nerede Çalıştım?",
      //Design
      designtitle: "Yaratıcı Düşünmek \nNeden Önemli?",
      designcontent:
        "Yaratıcı düşünme, problem çözme becerilerimizi geliştirirken, farklı bakış açıları ve yenilikçi çözümler sunma yeteneğimizi artırır. İşte bu nedenle, herhangi bir projeye veya soruna yaklaşırken yaratıcı düşünme, karşılaşılan zorlukları aşmamızı sağlayan kritik bir araç haline gelir. Ayrıca, yaratıcı düşünme, esneklik ve adaptasyon yeteneğimizi güçlendirir, böylece değişen koşullara hızla uyum sağlamamıza yardımcı olur. Sonuç olarak, yaratıcı düşünme, başarı için temel bir unsur olarak önem kazanır ve yenilikçi çözümler sunarak işimizi benzersiz kılar.",
      //WhatIknow
      whatıknowtitle: "Neler Biliyorum?",
      whatıknowtitle2: "Hangi Programları Kullanıyorum?",
      //Poems
      poemtitle: "Şiirlerim",
      //Contact
      contacttitle: "Fikir Alışverişi ve İşbirliği İçin Bağlantı Kurun!",
      contactcontent:
        "Fikirlerinizi hayata geçirmek, projeler hakkında konuşmak veya sadece tasarımla ilgili sohbet etmek isterseniz, çekinmeden bana mesaj atabilirsiniz! İleti bırakmak için buradayım!",
      formsubject: "Ne hakkında?",
      formmail: "E-Posta adresiniz nedir?",
      formmessage: "Kendinizi tanıtıp ardından mesajınızı yazın",
      formbutton: "Mesajı Gönder",
      //Footer
      footertitle: "İrtibata Geç!",
      footercontent:
        "Senden Haber Bekliyor Olacağım. \n Bana Dönüş Yapmaktan Çekinme!",
      footertitle2: "İletişim Bilgileri",
      footermail: "omer.kaya.ekici@yandex.com",
      footerlocation: "Nilüfer / Bursa",
      footertitle3: "Sosyal Medya",
      footerphonecontent:
        "Benimle Aşağıdaki \n Bağlantılar Üzerinden de \n İletişime Geçebilirsin",
      Think: "Düşün,",
      Design: "Tasarla,",
      Pruduce: "Üret.",
      // Privacy Policy
      privacyhead: "Gizlilik Politikası",
      privacyıntroductionhead: "Giriş",
      privacyparagraph:
        "Bu gizlilik politikası, https://www.omerkayaekici.com/ Web Sitesi ziyaretçilerinin gizliliğini koruma taahhüdümüzü açıklar. Kişisel bilgilerin nasıl toplandığını, kullanıldığını ve korunduğunu açıklar.",
      InformationCollectedhead: "Toplanan Bilgiler",
      Informationparagraph:
        "Web Sitemizi ziyaret ettiğinizde çeşitli türde bilgiler toplayabiliriz:",
      Informationli1:
        "Kişisel Bilgiler: İsim, soyisim ve e-posta adresi, ancak bu bilgileri yalnızca bir iletişim formu aracılığıyla gönüllü olarak sağlarsanız toplarız.",
      Informationli2:
        "Ziyaret Bilgileri: IP adresi, tarayıcı türü, ziyaret süresi ve sayfa görüntülemeleri, Web Sitesi'nin performansını ve kullanıcı deneyimini analiz etmek ve iyileştirmek için.",
      UseofInformationhead: "Bilgilerin Kullanımı",
      UseofInformationparagraph:
        "Topladığımız bilgiler aşağıdaki amaçlarla kullanılabilir:",
      UseofInformationli1: "Hizmetlerimizi sağlamak ve geliştirmek için,",
      UseofInformationli2: "Kullanıcı deneyimini artırmak için,",
      UseofInformationli3: "Web Sitesi'nin işlevselliğini optimize etmek için,",
      UseofInformationli4:
        "Sadece onay verdiyseniz, pazarlama ve iletişim amaçları için.",
      CookiesandSimilarTechnologieshead: "Çerezler ve Benzeri Teknolojiler",
      CookiesandSimilarTechnologiesparagraph:
        "Web Sitesi, kullanıcı deneyimini artırmak için çerezler ve benzeri teknolojiler kullanabilir. Çerezler, tarayıcınıza kaydedilen küçük veri dosyalarıdır ve Web Sitesi'nin işlevselliğini ve kullanıcı etkileşimini iyileştirmeye yardımcı olur. Tarayıcı ayarlarınızı değiştirerek çerezleri reddedebilir veya çerez gönderildiğinde bildirim alabilirsiniz.",
      ThirdPartyServiceProvidershead: "Üçüncü Taraf Hizmet Sağlayıcılar",
      ThirdPartyServiceProvidersparagraph:
        "Web Sitemizi kolaylaştırmak, bizim adımıza hizmet sağlamak veya Web Sitemizin nasıl kullanıldığını analiz etmemize yardımcı olmak için üçüncü taraf şirketler ve bireylerle çalışabiliriz. Bu üçüncü taraflar, bu görevleri bizim adımıza yerine getirmek için kişisel bilgilerinize erişebilirler ve bu bilgileri başka amaçlarla ifşa etmemek veya kullanmamakla yükümlüdürler.",
      DataSecurityhead: "Veri Güvenliği",
      DataSecurityparagraph:
        "Kişisel bilgilerinizin güvenliğini sağlamak için çeşitli güvenlik önlemleri uygularız. Ancak, internet üzerinden iletim yöntemi veya elektronik depolama yöntemi %100 güvenli değildir ve mutlak güvenliği garanti edemeyiz.",
      ChangestoThisPrivacyPolicy:
        "Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Yeni Gizlilik Politikası'nı bu sayfaya yayınlayarak sizi her türlü değişiklikten haberdar edeceğiz. Değişiklikler bu sayfaya yayınlandıktan hemen sonra yürürlüğe girer.",
      ContactUshead: "Bize Ulaşın",
      ContactUsparagraph:
        "Bu Gizlilik Politikası hakkında herhangi bir sorunuz varsa, lütfen bize şu adresten ulaşın:",
    },
  },
  en: {
    translation: {
      home: "Home",
      works: "Works",
      skills: "Skills",
      poems: "Poems",
      contact: "Contact",
     blog:"Blog",
      // Home Section
      hometitle: "A Passionate Web Developer Living in Bursa",
      homecontent:
        "Hello! My name is Ömer Kaya Ekici. I am a front-end developer and I approach my work with great passion and commitment. As a professional developer, I have a total of 2 years of experience with 1 year of freelancing and 1 year of corporate experience. During this time, I have acquired the necessary knowledge and skills to successfully complete your projects. ",
      homebuttonworks: "Check out my projects",
      homebuttoncv: " View CV",
      //Works
      //WhereIWorks
      whereıworkstitle: "Where Have I Worked Before?",
      //Design
      designtitle: "Why Creative Thinking \n Is Important?",
      designcontent:
        "Creative thinking enhances our problem-solving skills and increases our ability to offer different perspectives and innovative solutions. This is why, when approaching any project or problem, creative thinking becomes a critical tool that enables us to overcome challenges. Furthermore, creative thinking strengthens our ability to be flexible and adaptive, thus helping us to quickly adjust to changing circumstances. As a result, creative thinking becomes an essential element for success and makes our business unique by providing innovative solutions.",
      //WhatIknow
      whatıknowtitle: "What  I Know?",
      whatıknowtitle2: "Which Programs Do I Use?",
      //Poems
      poemtitle: "My Poems",
      //Contact
      contacttitle: "Connect to Exchange Ideas and Collaborate!",
      contactcontent:
        "If you want to bring your ideas to life, talk about projects or just chat about design, feel free to message me! I'm here to leave a message!",
      formsubject: "What is it about?",
      formmail: "What is your e-mail address?",
      formmessage: "Introduce yourself and then write your message",
      formbutton: "Send Message",
      //Footer
      footertitle: "Get in Touch!",
      footercontent:
        "I'll be waiting to hear from you. \n Feel free to get back to me!",
      footertitle2: "Contact Information",
      footermail: "omer.kaya.ekici@yandex.com",
      footerlocation: "Nilüfer / Bursa",
      footertitle3: "Social Media",
      footerphonecontent: "You can also contact me \n via the following links",
      //preloader
      Think: "Think,",
      Design: "Design,",
      Pruduce: "Product.",
      // Privacy Policy
      privacyhead: "Privacy Policy",
      privacyıntroductionhead: "Introduction",
      privacyparagraph:
        "This privacy policy outlines our commitment to protecting the privacy of visitors to https://www.omerkayaekici.com/ Website . It describes how personal information is collected, used, and protected.",
      InformationCollectedhead: "Information Collected",
      Informationparagraph:
        "We may collect various types of information when you visit our Website:",
      Informationli1:
        "Personal Information: Name, surname, and email address, but only if you voluntarily provide this information through a contact form.",
      Informationli2:
        "Visit Information: IP address, browser type, visit duration, and page views to analyze and improve the Website's performance and user experience.",
      UseofInformationhead: "Use of Information",
      UseofInformationparagraph:
        "The information we collect may be used for the following purposes:",
      UseofInformationli1: "o provide and improve our services,",
      UseofInformationli2: "To enhance user experience,",
      UseofInformationli3: "To optimize the Website's functionality,",
      UseofInformationli4:
        "For marketing and communication purposes, only if you have given consent.",
      CookiesandSimilarTechnologieshead: "Cookies and Similar Technologies",
      CookiesandSimilarTechnologiesparagraph:
        "The Website may use cookies and similar technologies to enhance user experience. Cookies are small data files stored on your browser that help improve Website functionality and user interaction. You can adjust your browser settings to refuse cookies or to notify you when cookies are being sent.",
      ThirdPartyServiceProvidershead: "Third-Party Service Providers",
      ThirdPartyServiceProvidersparagraph:
        "We may employ third-party companies and individuals to facilitate our Website, provide services on our behalf, or assist us in analyzing how our Website is used. These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.",
      DataSecurityhead: "Data Security",
      DataSecurityparagraph:
        "We implement a variety of security measures to maintain the safety of your personal information. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.",
      ChangestoThisPrivacyPolicy:
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective immediately upon posting to this page.",
      ContactUshead: "Contact Us",
      ContactUsparagraph:
        "If you have any questions about this Privacy Policy, please contact us at:",
    },
  },

  de: {
    translation: {
      home: "Startseite",
      works: "Arbeiten",
      skills: "Fähigkeiten",
      poems: "Gedichte",
      contact: "Kontakt",
      blog:"Blog",
      // Abschnitt "Home
      hometitle: "Ein leidenschaftlicher Webentwickler, der in Bursa lebt",
      homecontent:
        "Hallo! Mein Name ist Ömer Kaya Ekici. Ich bin ein Front-End-Entwickler und gehe meine Arbeit mit großer Leidenschaft und Engagement an. Als professioneller Entwickler verfüge ich über insgesamt 2 Jahre Erfahrung, davon 1 Jahr als Freiberufler und 1 Jahr in einem Unternehmen. In dieser Zeit habe ich mir die notwendigen Kenntnisse und Fähigkeiten angeeignet, um Ihre Projekte erfolgreich abzuschließen. ",
      homebuttonworks: "Sehen Sie sich meine Projekte an",
      homebuttoncv: " Lebenslauf ansehen",
      //Arbeiten
      //WoMeineArbeiten
      whereıworkstitle: "Wo habe ich schon gearbeitet?",
      //Gestaltung
      designtitle: "Warum ist kreatives Denken \n wichtig?",
      designcontent:
        "Kreatives Denken verbessert unsere Problemlösungsfähigkeiten und erhöht unsere Fähigkeit, verschiedene Perspektiven und innovative Lösungen anzubieten. Deshalb wird kreatives Denken bei der Bewältigung von Projekten und Problemen zu einem wichtigen Instrument, mit dem wir Herausforderungen meistern können. Darüber hinaus stärkt kreatives Denken unsere Fähigkeit, flexibel und anpassungsfähig zu sein, und hilft uns so, uns schnell auf veränderte Umstände einzustellen. Infolgedessen wird kreatives Denken zu einem wesentlichen Element für den Erfolg und macht unser Unternehmen durch die Bereitstellung innovativer Lösungen einzigartig.",
      //WasWissen
      whatıknowtitle: "Was weiß ich?",
      whatıknowtitle2: "Welche Programme verwende ich?",
      //Gedichte
      poemtitle: "Meine Gedichte",
      //Kontakt
      contacttitle:
        "Verbinden Sie sich, um Ideen auszutauschen und zusammenzuarbeiten!",
      contactcontent:
        "Wenn Sie Ihre Ideen verwirklichen, über Projekte sprechen oder einfach nur über Design plaudern wollen, können Sie mir eine Nachricht schicken! Ich bin hier, um eine Nachricht zu hinterlassen!",
      formsubject: "Worum geht es?",
      formmail: "Wie lautet Ihre E-Mail Adresse?",
      formmessage: "Stellen Sie sich vor und schreiben Sie dann Ihre Nachricht",
      formbutton: "Nachricht senden",
      //Fußzeile
      footertitle: "Nehmen Sie Kontakt auf!",
      footercontent:
        "Ich freue mich darauf, von Ihnen zu hören. \n  Sie können sich gerne bei mir melden!",
      footertitle2: "Kontaktinformationen",
      footermail: "omer.kaya.ekici@yandex.com",
      footerlocation: "Nilüfer / Bursa",
      footertitle3: "Soziale Medien",
      footerphonecontent:
        "Sie können mich \n auch  über die \n folgenden Links \n kontaktieren",
      //Preloader
      Think: "Denken,",
      Design: "Entwerfen,",
      Pruduce: "Produkt.",
      // Privacy Policy
      privacyhead: "Datenschutzbestimmungen",
      privacyıntroductionhead: "Einführung",
      privacyparagraph:
        "Diese Datenschutzrichtlinie beschreibt unsere Verpflichtung zum Schutz der Privatsphäre der Besucher der Website https://www.omerkayaekici.com/. Sie erklärt, wie persönliche Informationen gesammelt, verwendet und geschützt werden.",
      InformationCollectedhead: "Gesammelte Informationen",
      Informationparagraph:
        "Wir können verschiedene Arten von Informationen sammeln, wenn Sie unsere Website besuchen:",
      Informationli1:
        "Persönliche Informationen: Vorname, Nachname und E-Mail-Adresse, aber wir sammeln diese Informationen nur, wenn Sie sie freiwillig über ein Kontaktformular angeben.",
      Informationli2:
        "Besuchsinformationen: IP-Adresse, Browsertyp, Besuchsdauer und Seitenaufrufe, um die Leistung und das Nutzererlebnis auf der Website zu analysieren und zu verbessern.",
      UseofInformationhead: "Verwendung von Informationen",
      UseofInformationparagraph:
        "Die von uns gesammelten Informationen können für die folgenden Zwecke verwendet werden:",
      UseofInformationli1:
        "Zur Bereitstellung und Verbesserung unserer Dienstleistungen,",
      UseofInformationli2: "Zur Verbesserung der Benutzerfreundlichkeit,",
      UseofInformationli3: "Um die Funktionalität der Website zu optimieren,",
      UseofInformationli4:
        "Nur wenn Sie Ihre Zustimmung gegeben haben, für Marketing- und Kommunikationszwecke.",
      CookiesandSimilarTechnologieshead: "Çerezler ve Benzeri Teknolojiler",
      CookiesandSimilarTechnologiesparagraph:
        "Die Website kann Cookies und ähnliche Technologien verwenden, um die Benutzerfreundlichkeit zu verbessern. Cookies sind kleine Datendateien, die in Ihrem Browser gespeichert werden und dazu beitragen, die Funktionalität der Website und die Interaktion mit dem Nutzer zu verbessern. Sie können Cookies ablehnen, indem Sie Ihre Browser-Einstellungen ändern oder eine Benachrichtigung erhalten, wenn Cookies gesendet werden.",
      ThirdPartyServiceProvidersparagraph:
        "Wir können mit Drittunternehmen und Einzelpersonen zusammenarbeiten, um unsere Website zu erleichtern, Dienstleistungen in unserem Namen zu erbringen oder uns bei der Analyse der Nutzung unserer Website zu helfen. Diese Dritten können Zugang zu Ihren persönlichen Daten haben, um diese Aufgaben in unserem Namen zu erfüllen, und sind verpflichtet, diese Daten nicht weiterzugeben oder für andere Zwecke zu verwenden.",
      DataSecurityhead: "Datensicherheit",
      DataSecurityparagraph:
        "Wir ergreifen verschiedene Sicherheitsmaßnahmen, um die Sicherheit Ihrer persönlichen Daten zu gewährleisten. Die Methode der Übertragung über das Internet oder die Methode der elektronischen Speicherung ist jedoch nicht zu 100 % sicher und wir können keine absolute Sicherheit garantieren.",
      ChangestoThisPrivacyPolicy:
        "Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen. Die Änderungen werden sofort nach ihrer Veröffentlichung auf dieser Seite wirksam.",
      ContactUshead: "Kontakt",
      ContactUsparagraph:
        "Wenn Sie Fragen zu dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte unter:",
    },
  },

  ru: {
    translation: {
      home: "Главная",
      works: "Работы",
      skills: "Навыки",
      poems: "Стихи",
      contact: "Контакт",
      blog:"Блог",
      // Главная секция
      hometitle: "Увлеченный веб-разработчик, живущий в Бурсе",
      homecontent:
        "Здравствуйте! Меня зовут Ömer Kaya Ekici. Я фронтенд-разработчик, и я подхожу к своей работе с большой страстью и приверженностью. Как профессиональный разработчик, я имею в общей сложности 2 года опыта, из которых 1 год фриланса и 1 год корпоративного опыта. За это время я приобрел необходимые знания и навыки для успешного выполнения ваших проектов. ",
      homebuttonworks: "Посмотрите мои проекты",
      homebuttoncv: "Посмотреть резюме",
      //Works
      //WhereIWorks
      whereıworkstitle: "Где я работал раньше?",
      //Design
      designtitle: "Почему креативное мышление \n важно?",
      designcontent:
        "Креативное мышление улучшает наши навыки решения проблем и повышает нашу способность предлагать различные точки зрения и инновационные решения. Именно поэтому при подходе к любому проекту или проблеме творческое мышление становится важнейшим инструментом, позволяющим нам преодолевать трудности. Кроме того, творческое мышление усиливает нашу способность быть гибкими и адаптивными, помогая быстро приспосабливаться к меняющимся обстоятельствам. В результате творческое мышление становится важнейшим элементом успеха и делает наш бизнес уникальным, предлагая инновационные решения.",
      //WhatIknow
      whatıknowtitle: "Что я знаю?",
      whatıknowtitle2: "Какие программы я использую?",
      //Poems
      poemtitle: "Мои стихи",
      //Contact
      contacttitle: "Подключайтесь для обмена идеями и совместной работы!",
      contactcontent:
        "Если вы хотите воплотить свои идеи в жизнь, обсудить проекты или просто поболтать о дизайне, смело пишите мне! Я здесь, чтобы оставить сообщение!",
      formsubject: "О чем это?",
      formmail: "Какой у вас адрес электронной почты?",
      formmessage: "Представьтесь, а затем напишите свое сообщение",
      formbutton: "Отправить сообщение",
      //Футер
      footertitle: "Свяжитесь с нами!",
      footercontent:
        "Я буду ждать от вас новостей. \n Не стесняйтесь связаться со мной!",
      footertitle2: "Контактная информация",
      footermail: "omer.kaya.ekici@yandex.com",
      footerlocation: "Nilüfer / Bursa",
      footertitle3: "Социальные сети",
      footerphonecontent:
        "Вы также можете \n связаться   со мной \n по следующим ссылкам",
      Think: "думать,",
      Design: "дизайн,",
      Pruduce: "Продукт.",
      // Политика конфиденциальности
      privacyhead: "Политика конфиденциальности",
      privacyıntroductionhead: "Введение",
      privacyparagraph:
        "Данная политика конфиденциальности описывает наши обязательства по защите частной жизни посетителей сайта https://www.omerkayaekici.com/. Она объясняет, как собирается, используется и защищается личная информация",
      InformationCollectedhead: "Собираемая информация",
      InformationParagraph:
        "Мы можем собирать различные типы информации, когда вы посещаете наш сайт:",
      Informationli1:
        "Личная информация: Имя, фамилия и адрес электронной почты, но мы собираем эту информацию только в том случае, если вы предоставляете ее добровольно через контактную форму.",
      Informationli2:
        "Информация о посещении: IP-адрес, тип браузера, продолжительность посещения и просмотры страниц для анализа и улучшения производительности и пользовательского опыта на сайте.",
      UseofInformationhead: "Использование информации",
      UseofInformationparagraph:
        "Собираемая нами информация может быть использована в следующих целях:",
      UseofInformationli1: "Для предоставления и улучшения наших услуг",
      UseofInformationli2: "Для улучшения пользовательского опыта",
      UseofInformationli3: "Для оптимизации функциональности веб-сайта",
      UseofInformationli4:
        "Только с вашего согласия, в маркетинговых и коммуникационных целях",
      CookiesandSimilarTechnologieshead: "Çerezler ve Benzeri Teknolojiler",
      CookiesandSimilarTechnologiesparagraph:
        "Сайт может использовать файлы cookie и аналогичные технологии для улучшения качества обслуживания пользователей. Cookies - это небольшие файлы данных, которые хранятся в вашем браузере и помогают улучшить функциональность сайта и взаимодействие с пользователем. Вы можете отказаться от использования файлов cookie, изменив настройки браузера, или получить уведомление об отправке файлов cookie.",
      ThirdPartyServiceProviderparagraph:
        "Мы можем сотрудничать со сторонними компаниями и частными лицами для поддержки нашего сайта, предоставления услуг от нашего имени или помощи в анализе использования нашего сайта. Эти третьи лица могут иметь доступ к вашей личной информации для выполнения этих задач от нашего имени и обязаны не раскрывать и не использовать эту информацию для любых других целей",
      DataSecurityhead: "Безопасность данных",
      DataSecurityparagraph:
        "Мы принимаем различные меры безопасности для обеспечения сохранности ваших персональных данных. Однако метод передачи данных через Интернет или метод электронного хранения не является на 100% безопасным, и мы не можем гарантировать абсолютную безопасность.",
      ChangestoThisPrivacyPolicy:
        "Мы можем время от времени обновлять нашу политику конфиденциальности. Мы будем уведомлять вас о любых изменениях, публикуя новую политику конфиденциальности на этой странице. Изменения вступят в силу сразу после их размещения на этой странице.",
      ContactUshead: "Свяжитесь с нами",
      ContactUsparagraph:
        "Если у вас возникли вопросы по поводу данной политики конфиденциальности, пожалуйста, свяжитесь с нами по адресу:",
    },
  },
  az: {
    translation: {
      home: "Əsas Səhifə",
      works: "İşlərim",
      skills: "Bacarıqlarım",
      poems: "Şeirlərim",
      contact: "Əlaqə",
      blog:"Blog",
      // Home Section
      hometitle: "Bursada yaşayan Maraqlı bir Web Developer.",
      homecontent:
        "Salam! Mənim adım Ömer Kaya Ekici. Front-end developerəm və işimi böyük bir maraqla və məsuliyətlə yanaşırım. Profesional developer kimi, 1 il freelancer iş və 1 il də korporativ təcrübəm ilə ümumilikdə 2 il təcrübəyə sahibəm. Bu müddət ərzində layihələri uğurla həyata keçirmək üçün lazımi bilik və bacarıqları əldə etdim. ",
      homebuttonworks: "İşlərimə Bax",
      homebuttoncv: "CV Görüntüle",
      //Works
      //WhereIWorks
      whereıworkstitle: "Daha Əvvəl Hansı Yerlərdə İşlədim?",
      //Design
      designtitle: "Yaradıcı Düşünmək \nNəyə Nəzərə Alınmalıdır?",
      designcontent:
        "Yaradıcı düşünmə, məsələləri həll edərkən, müxtəlif baxış nöqtələri və yenilikçi həllər təklif edə biləcəyimiz bacarığımızı artırır. Beləliklə, hər hansı bir layihəyə və ya probleme yanaşarkən, yaradıcı düşünmə, qarşılaşdığımız çətinlikləri aşmağımıza kömək edən kritik bir alətə çevrilir. Həmçinin, yaradıcı düşünmə, esneklik və adaptasiya bacarığımızı möhkəmləndirir, beləliklə dəyişən şəraitə tez bir şəkildə uyğunlaşmamıza kömək edir. Nəticədə, yaradıcı düşünmə, uğur üçün əsas bir unsur olaraq əhəmiyyət qazanır və innovativ həllər təklif edərək bizim işimizi unikal edir.",
      //WhatIknow
      whatıknowtitle: "Nə Bilirəm?",
      whatıknowtitle2: "Hansı Proqramları İstifadə Edirəm?",
      //Poems
      poemtitle: "Şeirlərim",
      //Contact
      contacttitle: "Fikir Mübadiləsi və Əməkdaşlıq Üçün Əlaqə Qura!",
      contactcontent:
        "Fikirlərinizi həyata keçirmək, layihələr haqqında müzakirələr aparmaq və ya sadəcə dizaynla bağlı söhbət etmək istəyirsinizsə, mənə çəkinmədən mesaj atırsınız! Mesaj qoymaq üçün burdayam!",
      formsubject: "Nə haqqında?",
      formmail: "E-Poçt ünvanınız nədir?",
      formmessage: "Özünüzü təqdim edib sonra mesajınızı yazın",
      formbutton: "Mesajı Göndər",
      //Footer
      footertitle: "Əlaqədə Ol!",
      footercontent: "Səndən Xəbər Gözləyirəm. \n Mənə Qayıtmaqdan çəkinmə!",
      footertitle2: "Əlaqə Məlumatları",
      footermail: "omer.kaya.ekici@yandex.com",
      footerlocation: "Nilüfer / Bursa",
      footertitle3: "Sosial Media",
      footerphonecontent:
        "Mənimlə Aşağıdakı \n Bağlantılar Üzərində də \n Əlaqə Qura Bilərsən",
      Think: "Düşün,",
      Design: "Dizayn et,",
      Pruduce: "İstehsal et.",
      // Gizlilik Siyasəti
      privacyhead: "Məxfilik Siyasəti",
      privacyintroductionhead: "Giriş",
      privacyparagraph:
        "Bu məxfilik siyasəti https://www.omerkayaekici.com/ Veb saytına daxil olanların məxfiliyini qorumaq öhdəliyimizi izah edir. O, şəxsi məlumatların necə toplandığını, istifadə edildiyini və qorunduğunu izah edir.",
      InformationCollectedhead: "Toplanmış məlumat",
      Informationparagraph:
        "Veb saytımıza daxil olduğunuz zaman biz müxtəlif növ məlumat toplaya bilərik:",
      Informationli1:
        "Şəxsi Məlumat: Ad, soyad və e-poçt ünvanı, lakin biz bu məlumatı yalnız könüllü olaraq əlaqə forması vasitəsilə təqdim etdiyiniz təqdirdə toplayacağıq.",
      Informationli2:
        "Ziyarətçi Məlumatı: Vebsaytın performansını və istifadəçi təcrübəsini təhlil etmək və təkmilləşdirmək üçün IP ünvanı, brauzer növü, ziyarət müddəti və səhifə baxışları.",
      UseofInformationhead: "Məlumatın istifadəsi",
      UseofInformationparagraph:
        "Topladığımız məlumat aşağıdakı məqsədlər üçün istifadə edilə bilər:",
      UseofInformationli1: "Xidmətlərimizi təmin etmək və təkmilləşdirmək,",
      UseofInformationli2: "İstifadəçi təcrübəsini yaxşılaşdırmaq üçün,",
      UseofInformationli3: "Vebsaytın funksionallığını optimallaşdırmaq üçün,",
      UseofInformationli4:
        "Marketinq və kommunikasiya məqsədləri üçün yalnız razılığınızı vermisinizsə.",
      CookiesandSimilarTechnologieshead: "Kukilər və Oxşar Texnologiyalar",
      CookiesandSimilarTechnologiesparagraph:
        "Veb-sayt istifadəçi təcrübəsini yaxşılaşdırmaq üçün kukilərdən və oxşar texnologiyalardan istifadə edə bilər. Kukilər brauzerinizdə saxlanılan kiçik məlumat fayllarıdır və Vebsaytın funksionallığını və istifadəçi ilə qarşılıqlı əlaqəni yaxşılaşdırmağa kömək edir. Brauzerinizi dəyişdirməklə kukiləri rədd edə və ya kuki göndərilərkən bildirişlər ala bilərsiniz. parametrlər.",
      ThirdPartyServiceProvidershead: "Üçüncü Tərəf Xidmət Provayderləri",
      ThirdPartyServiceProvidersparaqrafı:
        "Biz Vebsaytımızı asanlaşdırmaq, bizim adımızdan xidmətlər göstərmək və ya Veb saytımızın necə istifadə edildiyini təhlil etməkdə bizə kömək etmək üçün üçüncü tərəf şirkətləri və fərdləri ilə işləyə bilərik. Bu üçüncü tərəflər bizim adımızdan bu vəzifələri yerinə yetirmək üçün şəxsi məlumatlarınıza daxil ola bilər və bu məlumatları açıqlamamağa və ya başqa məqsədlər üçün istifadə etməməyə borcludurlar.",
      DataSecurityhead: "Məlumat Təhlükəsizliyi",
      DataSecurityparagraph:
        "Biz sizin şəxsi məlumatlarınızın təhlükəsizliyini təmin etmək üçün müxtəlif təhlükəsizlik tədbirləri həyata keçiririk. Bununla belə, İnternet üzərindən heç bir ötürmə üsulu və ya elektron saxlama metodu 100% təhlükəsiz deyil və biz mütləq təhlükəsizliyə zəmanət verə bilmərik.",
      ChangestoThisPrivacyPolicy:
        "Biz vaxtaşırı Məxfilik Siyasətimizi yeniləyə bilərik. Yeni Məxfilik Siyasətini bu səhifədə yerləşdirməklə hər hansı dəyişiklik barədə sizi xəbərdar edəcəyik. Dəyişikliklər bu səhifədə dərc edildikdən dərhal sonra qüvvəyə minəcək.",
      ContactUshead: "Bizimlə əlaqə saxlayın",
      ContactUsparagraph:
        "Bu Məxfilik Siyasəti ilə bağlı hər hansı sualınız varsa, lütfən bizimlə əlaqə saxlayın:",
    },
  },
};
i18n.use(initReactI18next).init({
  lng: "tr",
  resources,
});
export default i18n;
