import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import WhereIWorks from './pages/WhereIWorks';
import Design from './pages/Design';
import Works from './pages/Works';
import WhatIKnow from './pages/WhatIKnow';
import Contact from './pages/Contact';
import Poem from './pages/Poem';
import Footer from './components/Footer';
import PreLoader from './components/PreLoader';
import Policy from './pages/Policy';

function App() {
  const [showPolicy, setShowPolicy] = useState(false);

  // Dark mode'u toggle etmek için kullanılacak işlev
  function toggleDarkMode() {
    const isDarkMode = document.documentElement.classList.toggle('dark');
    localStorage.setItem('darkMode', isDarkMode ? 'on' : 'off');
  }

  // Sayfa yüklendiğinde dark mode tercihini kontrol etmek için kullanılacak işlev
  function checkDarkModePreference() {
    const darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'on') {
      document.documentElement.classList.add('dark');
    }
  }

  // Sayfa yüklendiğinde dark mode tercihini kontrol et
  React.useEffect(() => {
    checkDarkModePreference();
  }, []);

  return (
    <Router>
      <PreLoader />
      <div className={`App ${showPolicy ? '' : 'dark:bg-black dark:text-white'}`}>
        <Navbar toggleDarkMode={toggleDarkMode} setShowPolicy={setShowPolicy} />
        
        <Routes>
          <Route path="/" element={
            <>
              <Home />
              <Works />
              <WhereIWorks />
              <Design />
              <WhatIKnow />
              <Poem />
              <Contact />
            </>
          } />
          <Route path="/policy" element={<Policy />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;