const poemData = [
  {
    title: "Farkına Varır mı İnsan?",
    image: "/img/poem/sad.webp",
    content:
      " \n Farkına varır mı insan bilmem \n bir iç çekerken kendine \n Şu anın güzelliğine? \n Şükreder mi haline? \n  \n Yoksa dert edip zehreder mi kendine? \n Kıymet bilmez insan, neylesin şu anı \n aklı başka yerdedir bilmez zamanı \n  \n Aklını değil, çoğu zaman kalbini kullanır \n Bilir ki kalp insanın aynasıdır \n Bir umutla besler kendini, bir umutla yaşlanır... \n  \n Sevgi uslanmaz bir çocuk gibidir \n Hata yapsan da kendini sana affettirir \n Seni sevgiden değil kendinden mahrum ettirir \n Yalnızlıkla boğuşur, içindeki ateşle kavrulur. \n",
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed:
      "https://www.youtube.com/embed/65iJSnGXT3Y?si=qV65aGJ8hAYgfhFK",
  },
  {
    title: "Zamanla Anladım",
    image: "/img/poem/sad-2.webp",
    content: `\n Birini sevmeye kendinden başlamalıymış insan \n Önce kendini fark edip sonra başkasını sevebilirmiş \n Oysa kendini sevmeye başlamadan öncede sevebiliyormuş insan \n  \n Sevdiğimde, anladım... \n Onu sevebilmek için varını yoğunu ona adamakmış sevmek \n Gözlerini güldürmeyi istediğimde anladım \n  \n Kimi aşklar ulaşılmazdır \n Ulaşılmaz olduğunda onu ne kadar sevdiğini anlıyormuşsun \n Hasretinle kavrulduğumda anladım \n  \n Seni seviyorum diyememek kadar can acıtan bir şeyin olmadığını \n Ne yapıp etsem de seni kazanamadığımı \n seni kaybetmekten korkmaya başladığımda anladım... \n  \n Aşkı; sen başka birini sevdiğini öğrendiğimde \n Acaba nasıl birini seviyor? O seni üzer mi diye \n düşünmeye başladığımda anladım... \n  \n Şimdilerde lütfen yanlış anlama beni \n seni o kadar seviyorum ki, senin onu sevdiğini \n ben de seni sevdiğimde anladım.... \n  \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed:
      "https://www.youtube.com/embed/lPMZWBbAVlI?si=-UaNErxQ0lJ8Tfch",
  },
  {
    title: "Bir Kor Var Ki",
    image: "/img/poem/sad-3.webp",
    content: `\n Gene güneş battı \n Günle beraber umutlar da rafa kalktı \n Geleceğin o günü beklemekse gene bana kaldı \n Ne zaman geleceksin? \n  \n Ne zaman doğacak tekrar güneş? \n Ben Yalnızlığımla başa baş \n Kalbim sensiz artık bir taş \n Sonbahardayım her gün kötü günüm \n  \n Senden sonra gelen olur mu yerine? \n Gönlüm hasret kaldı güzel yüzüne \n Ne zamandır ayrı kaldık birbirimize? \n Oysa ne kadar mutluyduk daha dün \n  \n Ne çabuk geçti bu ömür \n çimde bir his var… \n İçimde gittiğin günden beri bir kor var \n Bir kor ki var olan beni artık sen yapan \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed: "https://www.youtube.com/embed/cevyrMxJfY8?si=HznWoWRSGOnQH1BP",
  },
   {
    title: "Seninle Sınandı Ömrüm",
    image: "/img/poem/sad-4.webp",
    content: `\n Söz vermiştim kendime sana bir daha yazmayacaktım hani \n Olmadı, ne yaptıysam bıraktığın boşluğu dolduramadım \n Yokluğundu belki de benim de sınavım \n  \n  \n Senle sınandı ömrüm \n Seninle yatıp seninle kalktım \n Seninle güldüm seninle ağladım \n  \n Seninle anladım... \n Ne yaptıysam sırf kaderim de sen ol istediğim için yaptım \n Ama olmadı, gene yalnız kalan ben oldum \n  \n Biliyorum belki de senin hiç umrunda bile olmadım \n Peki ya olsaydım? \n Beni ya yanlışlıkla sevseydin, kaldırabilir miydim? \n  \n Kaldıramazdım.... \n Üzgünüm daha fazla kendimi senle kandıramazdım \n O yüzden ben de seni kendime yasakladım \n Bir tek bana gülen resmin vardı sadece onu kendime sakladım \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed: "",
  },
  {
    title: "İnan Bana Ben de Yeni Öğrendim",
    image: "/img/poem/sad-5.webp",
    content: `\n Bir ömür bitirmek kolay mı sensiz? \n Gittiğin günden beri kendimi bulamadım \n Sen varken beni nasıl oyaladın? \n Çünkü gittiğin günden beri ben kendimi oyalayamadım \n Kalbim arafta sanki \n  \n Ne acı çekebiliyorum ne seni sevebiliyorum \n Senden sonra inan ne yapacağım bilmiyorum \n Yeni aşk mı aramalı yoksa \n Kalbe mühür mü vurmalı? \n  \n Sen söyle ben şimdi ne yapmalı? \n Ferhat gibi dağları mı delmeli \n Mecnun gibi çöllere mi düşmeli \n Ne önemi var ki bunların \n  \n Sen gittiğinden beri hayattan bir beklenti ummadım \n Ne başka bir bekleyen oldu beni \n Nede kalbini tamamen açıp misafir edecek biri \n Olsa da ben ister miydim? Orası ayrı tabii \n  \n Sen ister miydin başkasını sevme mi? \n Sen gittiğinden beri kalbim mühürü vurmuş çoktan \n İnan bana ben de yeni öğrendim \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed:
      "https://www.youtube.com/embed/Cy95bC_MsPs?si=zgsLIeRMQpn1I8yQ",
  },
  {
    title: "Kuşlar Dile Geldi Senden Sonra",
    image: "/img/poem/sad-6.webp",
    content: `\n Hatırlamak kolaydır tabii unutmaktansa \n Nasıl Unutabilir insan sevdiğini, seni seven kişiyi? \n Yılların verdiği birikimi nasıl olur da unutur insan \n Yıllarca uğraşsan da unutamazsın içinde ki demi \n  \n Sen, başardın çıkıp gittin \n Lakin sen bir çıkmaz sokaktın benim için \n Ben ne yaptıysam o çıkmazdan çıkamadım \n Hasretinle kavruldum, fakat anlatamadım sana sevgimi \n  \n Şair oldum şiirlere böldüm derdimi \n Her şey oldum senin için \n Ama Her şeyin olamadım senin \n Kuşlar dile geldi senden sonra \n  \n Günlerce, Aylarca haber getirdi \n Onlar da fark etmiş zannedersem ayrıldığımızı inan \n Ama seni merak ettiğim için kırmadılar beni \n Keşke aklımda ki gibi kalsaydın \n  \n Lakin yaralarını sarmak için bulmuşsun bir liman \n Sen toparlamaya başlamışsın çoktan ya ben, \n ben nasıl toparlayayım? Senden sonra her günüm hüsran \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed:
      "https://www.youtube.com/embed/rmLlneV_kiA?si=MXlg7YKNQmW9HoJ-",
  },  {
    title: "Sevgi Dediğimiz Şeylermiş Yalan Olanlar",
    image: "/img/poem/sad-7.webp",
    content: `\n Yüzün hiç bu kadar uzak kalmamıştı yüzüme \n sen benimsin sanmıştım \n ben de senin \n bir yabancıya bakıyormuş gözlerimiz \n bir yalan söylemiş dilimiz \n binlerce yalan işitmişiz \n Veda etmedim ben \n  \n sen de etme ne olursun \n gün olur bir yerde karşılaşırız tekrar \n sonra bir bakmışsın susmaz  dilimiz \n başlamış gene yalanlar \n en sonunda anlamışız meğer \n söylediklerimiz değil sadece yalan olan \n hayatımızmış yaptıklarımızmış sevgi dediğimiz şeylermiş yaşanan yalanlar... \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed:
      "https://www.youtube.com/embed/NTyCVzWU8wk?si=yhWpCSzq526vtrQD",
  },
  {
    title: "Suskun",
    image: "/img/poem/sad-8.webp",
    content: `\n Gittiğin günden beri \n Bir hüzün sardı bu bedeni \n Varlığındı tek yoldaşı bu bedenin \n  \n yalnız başına kaldı şimdi \n Hata yaptığında anlayamadı \n Çünkü hatasını anlatan bir sen vardı eskiden \n  \n Şimdiyse yalnız çaresiz ve suskun biri \n İçine içine anlattı derdini \n Sen gidince kapatmak istedi kendini bu beden \n  \n Hiç durmadan ağlamak istedi \n Ağlayınca her şeyin düzeleceğini düşündü sanki \n Yorulmuştu  çünkü artık tek savaşmaktan \n`,
    author: "Ömer Kaya Ekici (K!)",
    youtubeEmbed:
      "https://www.youtube.com/embed/D1zcuM7DPS4?si=ftrHZDI2EoB4J79i",
  },
  // Diğer şiirler buraya eklenebilir
];

export default poemData;
