import React from "react";
import { useTranslation } from "react-i18next";
const Policy = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto p-6 md:p-12 " id="policy">
      <div className="bg-white dark:bg-[#262626] shadow-md rounded-lg overflow-hidden">
        <div className="p-6 md:p-12">
          <h1 className="text-3xl font-extrabold mb-6 text-center">
            {t("privacyhead")}
          </h1>
          <section className="mb-6">
            <h2 className="text-2xl font-bold mb-2">
              {t("privacyıntroductionhead")}
            </h2>
            <p className="text-gray-700 dark:text-white">{t("privacyparagraph")}</p>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-bold mb-2">
              {t("InformationCollectedhead")}
            </h2>
            <p className="text-gray-700 dark:text-white">{t("Informationparagraph")}</p>
            <ul className="list-disc list-inside ml-6 text-gray-700 dark:text-white">
              <li>{t("Informationli1")}</li>
              <li>{t("Informationli2")}</li>
            </ul>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-bold mb-2">
              {t("UseofInformationhead")}
            </h2>
            <p className="text-gray-700 dark:text-white">{t("UseofInformationparagraph")}</p>
            <ul className="list-disc list-inside ml-6 text-gray-700 dark:text-white">
              <li>{t("UseofInformationli1")}</li>
              <li>{t("UseofInformationli2")}</li>
              <li>{t("UseofInformationli3")}</li>
              <li>{t("UseofInformationli4")}</li>
            </ul>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-bold mb-2">
              {t("CookiesandSimilarTechnologieshead")}
            </h2>
            <p className="text-gray-700 dark:text-white">
            {t("CookiesandSimilarTechnologiesparagraph")}
            </p>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-bold mb-2">
            {t("ThirdPartyServiceProvidershead")}
            </h2>
            <p className="text-gray-700 dark:text-white">
            {t("ThirdPartyServiceProvidersparagraph")}
            </p>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-bold mb-2">{t("DataSecurityhead")}</h2>
            <p className="text-gray-700 dark:text-white">
            {t("DataSecurityparagraph")}
            </p>
          </section>
          <section className="mb-6">
            <p className="text-gray-700 dark:text-white">
            {t("ChangestoThisPrivacyPolicy")}
            </p>
          </section>
          <section>
            <h2 className="text-2xl font-bold mb-2">{t("ContactUshead")}</h2>
            <p className="text-gray-700 dark:text-white">
            {t("ContactUsparagraph")}
              <a
                href="mailto:omer.kaya.ekici@yandex.com"
                className="text-blue-500"
              >
                omer.kaya.ekici@yandex.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Policy;
