import React from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from 'react-lazyload';
const Design = () => {
  const { t } = useTranslation();
    
  return (
    <LazyLoad scroll={true} offset={100}>
          <div className=" w-full mx-auto flex flex-col md:flex-row p-5 dark:bg-dark-gray bg-gray-100  lg:pt-36 lg:pb-36 justify-center items-center md:justify-start md:items-start">
      <div className="w-1/2 flex flex-col justify-center items-center md:justify-center md:items-center">
      <img src="/img/design.png" alt=""  className="dark:filter dark:invert dark:hue-rotate-180 md:mt-24 lg:mt-0" />



      </div>

      <div className="md:w-1/2 w-full md:pt-20 md:pb-20 p-4 justify-center   lg:mt-10 flex-col text-start">
        <h2 className="font-manix-sans font-black text-4xl text-left">
          {" "}
          {t('designtitle')}
        </h2>{" "}
        <p className=" font-manix-sans   italic font-semibold">
          {" "}
          {t('designcontent')}
        </p>
      </div>
    </div>
    </LazyLoad>

  );
};

export default Design;
