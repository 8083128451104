import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import poemData from "./PoemData";
import { useTranslation } from "react-i18next";
import LazyLoad from 'react-lazyload';
const Poem = () => {
  const [selectedPoem, setSelectedPoem] = useState(null);
  const { t } = useTranslation();
  const handleCardClick = (poem) => {
    setSelectedPoem(poem);
  };

  const handleClosePopup = () => {
    setSelectedPoem(null);
  };

  return (
    <LazyLoad scroll={true} offset={100}>
      <div className="flex justify-center items-center flex-col md:pt-20 md:pb-20 p-4 dark:text-white dark:bg-dark-gray bg-gray-100" id="poem">
      <h2 className=" text-3xl font-bold">{t('poemtitle')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-5 w-full xl:max-w-screen-2xl">
        {poemData.map((poem, index) => (
          <div
            key={index}
            className="shadow-xl w-full h-auto md:w-auto md:h-auto overflow-hidden p-6 border-gray border-2 mt-10 cursor-pointer"
            onClick={() => handleCardClick(poem)}
          >
            <img
              src={poem.image}
              alt={poem.title}
              className="w-full lg:max-w-md lg:h-auto h-[370px] mb-2 rounded-lg"
            />
            <h2 className="text-xl font-semibold">{poem.title}</h2>
          </div>
        ))}

        {selectedPoem && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white dark:bg-black rounded-lg p-8 max-w-4xl overflow-y-auto w-auto max-h-[80%] relative pt-0">
              <FontAwesomeIcon
                icon={faTimes}
                className="sticky top-0 left-[100%]  mt-5 pl-2 text-gray-500 cursor-pointer"
                size="lg"
                onClick={handleClosePopup}
              />
              {/* Eğer video linki yoksa resmi göster */}
              {selectedPoem.youtubeEmbed ? (
               <div style={{ display: 'flex', justifyContent: 'center' }}>
               <iframe
                 width="560"
                 height="315"
                 src={selectedPoem.youtubeEmbed}
                 title={selectedPoem.title}
                 frameBorder="0"
                 allowFullScreen
                 style={{ maxWidth: '100%' }}
               ></iframe>
             </div>
             
              ) : (
                <img
                  src={selectedPoem.image}
                  alt={selectedPoem.title}
                  className="w-full h-auto mb-4"
                />
              )}
              <h2 className="text-2xl font-semibold mb-2">
                {selectedPoem.title}
              </h2>
              <pre className="whitespace-pre-wrap text-left">
                {selectedPoem.content}
              </pre>
              <p className="mt-4 font-semibold">{selectedPoem.author}</p>
            </div>
          </div>
        )}
      </div>
    </div>
    </LazyLoad>
    
  );
};

export default Poem;
