export const fadeIn = (el) => {
    el.style.opacity = '0';
    el.style.transform = 'translateY(70px)';
    el.style.transition = 'opacity 1.5s, transform 1.5s';
    setTimeout(() => {
      el.style.opacity = '1';
      el.style.transform = 'translateY(0)';
    }, 100);
  };
  
  export const slideOut = (el) => {
    el.style.transition = 'transform 1s';
    el.style.transform = 'translateY(100%)'; // Slide down animation
    setTimeout(() => {
      el.style.display = 'none';
    }, 1000); // Display none after slide out animation
  };