import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBootstrap,
  faCss3Alt,
  faFigma,
  faHtml5,
  faJs,
  faPython,
  faReact,
  faUnity,
} from "@fortawesome/free-brands-svg-icons";
import { faCode, faDatabase, faN } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import LazyLoad from 'react-lazyload';

function WhatIKnow() {
  const { t } = useTranslation();
  return (
    <LazyLoad scroll={true} offset={100}>    <div className="flex justify-center md:pt-20 md:pb-20 p-4 items-center flex-col dark:bg-black dark:text-white bg-white" id="whatiknow">
    <h2 className=" text-3xl font-bold"> {t('whatıknowtitle')}</h2>
    <div className="grid grid-cols-3 md:grid-cols-8 gap-4 mb-5 mt-5">
      <div className="text-center">
        <FontAwesomeIcon icon={faReact} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">React</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faHtml5} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">HTML5</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faCss3Alt} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">CSS3</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faJs} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">JavaScript</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faBootstrap} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">Bootstrap</p>
      </div>

      <div className="text-center">
        <FontAwesomeIcon icon={faDatabase} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">MongoDB</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faPython} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">Python</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faDatabase} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">Mysql</p>
      </div>
    </div>

    <h2 className=" mt-20 text-3xl font-bold">
    {t('whatıknowtitle2')}
    </h2>
    <div className="grid grid-cols-3 md:grid-cols-4 gap-4  mb-5 mt-5">
      <div className="text-center">
        <FontAwesomeIcon icon={faCode} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">VsCode</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faN} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">Netlify</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faFigma} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">Figma</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faUnity} className="text-6xl mt-5" />
        <p className="text-lg mt-2 font-semibold">Unity</p>
      </div>
    </div>
  </div></LazyLoad>

  );
}

export default WhatIKnow;
