// index.js

import React, { useEffect } from "react";
import { fadeIn, slideOut } from '../animations';
import { useTranslation } from "react-i18next";
import './preloader.css'
const PreLoader = () => {
    const { t } = useTranslation();
    useEffect(() => {
      const texts = document.querySelectorAll('.texts-container span');
      texts.forEach((text, index) => {
        const delay = index * 400; // Adjust delay as needed
        setTimeout(() => {
          fadeIn(text);
        }, delay);
      });
      setTimeout(() => {
        const preloader = document.querySelector('.preloader');
        slideOut(preloader);
      }, texts.length * 400 + 1000); // Adjust duration as needed
    }, []);

  return (
    <div className="preloader">
      <div className="texts-container">
        <span> {t("Think")}</span>
        <span> {t("Design")}</span>
        <span> {t("Pruduce")}</span>
      </div>
    </div>
  );
};

export default PreLoader;
