import React from 'react';
import { useTranslation } from "react-i18next";
import LazyLoad from 'react-lazyload';
function Contact() {
  const { t } = useTranslation();
  return (
    <LazyLoad scroll={true} offset={100}>
         <div className="flex justify-center items-center flex-col dark:bg-black dark:text-white md:p-20 p-5" id="contact">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 dark:bg-gray-200 dark:text-black shadow-xl bg-gray-200 md:p-20 p-4 rounded-none md:rounded-3xl items-start justify-start w-full max-w-5xl">
        <div className="text-start">
          <h3 className="font-manix-sans font-black text-lg lg:text-4xl text-left mt-6 mb-6 ms-auto">
            {t('contacttitle')}
          </h3>{' '}
          <p className="ms-auto mx-auto my-auto">
          {t('contactcontent')}
          </p>
        </div>
        <div>
          <form
            id="contact_form" 
            method="POST"
            data-netlify="true"
            name="contact"
            action="/success" // Netlify, form gönderiminden sonra /success yoluna yönlendirir
            data-netlify-honeypot="bot-field"
            className="flex flex-col justify-between gap-4 mt-7"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="text"
              placeholder= {t('formsubject')}
              name="subject"
              id="subject"
              required
              className="p-[16px]"
            />
            <input
              type="email"
              placeholder={t('formmail')}
              name="email"
              id="email"
              required
              className="p-[16px]"
            />
            <textarea
              placeholder={t('formmessage')}
              name="message"
              id="message"
              required
              className="p-[16px] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></textarea>
            <button
              type="submit" 
              className="p-[16px] dark:bg-white bg-black  hover:bg-gray-500 text-white dark:text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t('formbutton')}
            </button>
          </form>
        </div>
      </div>
    </div>
    </LazyLoad>
 
  );
}

export default Contact;