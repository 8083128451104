import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const Home = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    let timer;
    if (!isHovered) {
      timer = setTimeout(() => {
        setImageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [isHovered, imageIndex]);

  return (
    <div className="container mx-auto flex flex-col-reverse md:flex-row justify-center items-center min-h-screen md:pt-20 md:pb-20 p-4" id="home">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-start md:justify-start md:items-start p-4">
        <h2 className="font-manix-sans font-black text-lg md:text-4xl text-left md:mt-10 md:mb-5">
        {t('hometitle')}
        </h2>

        <p className="text-[18px] text-left mb-6">
        {t('homecontent')}
        </p>

        <div className="flex w-full flex-grow flex-col md:flex-row gap-4 justify-center items-center md:items-start md:justify-start">
          <a
            href="#works"
            className="cursor-pointer group relative px-8 py-5 dark:bg-white dark:text-black bg-black bg-opacity-80 text-[#f1f1f1] rounded-lg hover:bg-opacity-70 transition font-semibold shadow-md"
          >
             {t('homebuttonworks')}
          </a>
          <a
            href="/pdf/CV.pdf"
            target="_blank"
            className="cursor-pointer group relative px-8 py-5 dark:bg-white dark:text-black bg-black bg-opacity-80 text-[#f1f1f1] rounded-lg hover:bg-opacity-70 transition font-semibold shadow-md"
          >
             {t('homebuttoncv')}
          </a>
        </div>
      </div>

      <div className="w-full mb-5 md:mb-36 md:w-1/2 justify-center flex items-center text-center">
        <div
          className="relative"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            src={imageIndex === 0 ? "/img/hoverhead.png" : "/img/head.png"}
            alt=""
            className={`ml-6 mx-auto absolute top-0 left-0 w-auto h-[350px] md:h-[550px] transition-opacity duration-300 ${
              isHovered ? "opacity-0" : ""
            }`}
          />
          <img
            src={imageIndex === 1 ? "/img/hoverhead.png" : "/img/head.png"}
            alt=""
            className={`w-auto transition-opacity duration-300 ${
              isHovered ? "opacity-100" : "opacity-0"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
