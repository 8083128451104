import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import {
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { useTranslation } from "react-i18next";
 const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="text-white overflow-visible md:overflow-hidden   lg:h-[400px]  px-4 sm:px-6 lg:px-0 w-[98%] flex justify-center items-center mx-auto ">
      <div className="rounded-tr-3xl overflow-visible rounded-tl-3xl bg-[#262626] w-full h-full flex flex-col md:flex-row justify-around px-4 sm:px-6 lg:px-20 mt-10">
        <div className="flex flex-col lg:flex-row lg:justify-start md:justify-center items-center gap-4  mb-8 md:mb-0 w-full">
          <div className="w-full lg:w-1/3 mr-4 mb-8 md:mb-0">
            <h2 className="font-semibold text-3xl mb-4 pt-8 md:text-center lg:text-start">
              {t('footertitle')}
            </h2>
            <p className="text-center md:text-center lg:text-start my-auto">
            {t('footercontent')}
            </p>
          </div>
          <div className="w-full lg:w-1/3 mr-4 mb-8 md:mb-0 mt-10">
            <h2 className="font-semibold text-3xl mb-4 md:text-center lg:text-start">
            {t('footertitle2')}
            </h2>
            <ul className="gap-2 gap-x-3 flex flex-col items-center lg:items-start text-left">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="ml-1"> {t('footermail')}</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faLocationDot} />
                <span className="ml-1"> {t('footerlocation')}</span>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/4  mb-8 md:mb-0 mt-6">
            <h2 className="font-semibold text-3xl mb-4 md:text-center lg:text-start mt-5">
            {t('footertitle3')}
            
            </h2>
            <ul className="grid grid-cols-3 gap-0 mx-auto lg:mx-0 items-center lg:items-start text-center w-1/3 lg:w-1/2 gap-y-2">
              <li>
                <a
                  href="https://www.linkedin.com/in/wuqqers/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="text-2xl hover:text-[#007ab9]"
                  />
                </a>
              </li>{" "}
              <li>
                <a
                  href="https://www.instagram.com/wuqqers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-2xl hover:text-[#f23389]"
                  />
                </a>
              </li>{" "}
              <li>
                <a
                  href="https://www.youtube.com/channel/UCNHPQks3ma-cg6EEjlVGW5w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="text-2xl hover:text-[#cc202d]"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/wuqqers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className="text-2xl hover:text-[#26a6d1]"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.github.com/wuqqers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faGithub}
                    className="text-2xl hover:text-[#434343]"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* phone görseline sahip div */}
        <div className="frame w-1/4 hidden lg:block ">
          <div className="frame-inline">
            <div className="content w-full">
              <img src="/img/1576.webp" alt="" className="phone " />
              <div className="flex flex-col cont w-full justify-center items-center gap-3 pt-96 ">
                <img
                  src="/img/head.png"
                  alt=""
                  className="w-[124px] h-auto logo mt-72 "
                />
                <h2 className="text-black font-semibold text-center text-sm">
                {t('footerphonecontent')}
                </h2>
                <a
                  href="https://discord.gg/KzQDEu3yya"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-transparent w-[150px] flex flex-row items-center justify-center hover:text-white border-2 border-[#262626] hover:bg-gray-800 text-black font-bold py-2 px-8 rounded-lg shadow-md"
                >
                  <img src="/img/discord.svg" alt="Discord icon" className="h-[18px] mr-3" />
                  <span>Discord</span>
                </a>
                <a
                  href="https://www.instagram.com/wuqqers"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-transparent  w-[150px] flex flex-row items-center justify-center hover:text-white border-2 border-[#262626] hover:bg-gray-800 text-black font-bold py-2 px-8 rounded-lg shadow-md"
                >
                  <img src="/img/instagram.svg" alt="Instagram icon" className="h-[18px] mr-3" />
                  <span>İnstagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
