import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from 'react-lazyload';

const images = [
  {
    id: 1,
    src: "/img/portfolio/bicorba.webp",
    alt: "Bicorba projesi",
    href: "https://www.bicorbabisalata.com/",
  },
  {
    id: 2,
    src: "/img/portfolio/bodor.webp",
    alt: "Bodor projesi",
    href: "https://www.bodor.com/tr/",
  },
  {
    id: 3,
    src: "/img/portfolio/converter.webp",
    alt: "Converter projesi",
    href: "https://www.safewebp.omerkayaekici.com/",
  },
  {
    id: 4,
    src: "/img/portfolio/deko.webp",
    alt: "Deko projesi",
    href: "http://dev.wepro.com.tr/dekocolor/",
  },
  {
    id: 5,
    src: "/img/portfolio/roda.webp",
    alt: "Roda projesi",
    href: "http://dev.wepro.com.tr/roda/",
  },
];

function Works() {
  const [imageRefs, setImageRefs] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    setImageRefs(images.map(() => React.createRef()));
  }, []);

  const renderImages = () => {
    return images.map((image, index) => {
      return (
        <div key={image.id} className="p-5">
          <a href={image.href} target="_blank" rel="noopener noreferrer">
            <div
              className="shadow-xl w-full h-[450px] md:w-auto md:h-[300px] lg:h-[450px] overflow-hidden p-6 border-gray border-2 mt-10"
              ref={imageRefs[index]}
              onMouseEnter={() => imageRefs[index]?.current?.classList.add("animated-img")}
              onMouseLeave={() => imageRefs[index]?.current?.classList.remove("animated-img")}
              style={{ backgroundImage: `url(${image.src})`, backgroundSize: 'cover' }}
            />
          </a>
        </div>
      );
    });
  };

  return (
    <LazyLoad scroll={true} offset={100}>
     <div className="flex justify-center items-center flex-col md:pt-20 md:pb-20 p-4 dark:bg-dark-gray bg-gray-100" id="works">
      <h2 className="text-3xl font-bold">{t('works')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-5 w-full xl:max-w-screen-2xl">
        {renderImages()}
      </div>
    </div>
    </LazyLoad>
   
  );
}

export default Works;
