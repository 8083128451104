import React from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
const WhereIWorks = () => {
  const { t } = useTranslation();
  return (
    <LazyLoad scroll={true} offset={100}>
      <div className="flex  dark:bg-black dark:text-white items-center justify-center md:justify-start bg-white gap-12 md:pt-20 md:pb-20 p-4 md:p-16 flex-col">
        <h2 className="font-manix-sans font-black text-lg md:text-4xl text-left">
          {t("whereıworkstitle")}
        </h2>
        <div className="flex flex-col md:flex-row gap-6 justify-center">
          <a href="https://www.cronwork.com/" target="_blank" className="mr-5">
            <img
              src="/img/cronwork.png"
              alt="Cronwork" // Add a descriptive alt text
              className="object-cover"
            />
          </a>
          <a href="https://www.avaneck.com/" target="_blank">
            <img
              src="/img/avane.png"
              alt="Avene" // Add a descriptive alt text
              className="object-cover"
            />
          </a>
        </div>
      </div>
    </LazyLoad>
  );
};

export default WhereIWorks;
